/*#root {*/
/*    display: flex;*/
/*    min-height: 100%;*/
/*}*/

body {
    background: #4B79A1;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #283E51, #4B79A1);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #283E51, #4B79A1); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.ant-layout {
    background: none;
}

.container {
    max-width: 1400px;
    margin: 2em auto;
}

.upload-container {
    display: inline-block;
    padding: 2rem;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #1e1e1e;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color .3s;
}

.upload-container .anticon-upload {
    font-size: 3rem;
    margin-bottom: 0.5rem;
}

@media (max-width: 1400px) {
    .container {
        margin: 2em;
    }
}

@media (max-width: 720px) {
    .ant-card-head-wrapper {
        flex-direction: column;
    }

    .ant-card-head-title {
        padding: 0.5em 0;
    }

    .ant-card-extra {
        margin-left: 0;
        padding: 0.5em 0;
        margin-top: -0.5em;
    }
}